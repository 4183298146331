import { type Dispatch, type SetStateAction, createContext, useState, useContext } from "react";

export interface FormError {
  [key: string]: string;
}

interface FormErrorsData {
  formErrors: FormError;
  setFormErrors: Dispatch<SetStateAction<FormError>>;
}

const FormErrorsContext = createContext<FormErrorsData | null>(null);

function FormErrorsProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [formErrors, setFormErrors] = useState<FormError>({
    type: "success",
    message: "",
    title: "",
  });

  return (
    <FormErrorsContext.Provider
      value={{
        formErrors: formErrors,
        setFormErrors: setFormErrors,
      }}
    >
      {children}
    </FormErrorsContext.Provider>
  );
}

function useFormErrors() {
  const context = useContext(FormErrorsContext);

  if (!context) {
    throw new Error("useFormErrors has been used out of scope.");
  }

  return context;
}

export { FormErrorsProvider, useFormErrors };
